import React from 'react'
import Layout from '../components/layout'
import Section from '../components/Section'
import Container from '../components/Container'
import Rsvp from '../components/rsvp'
import Header from '../components/header'
import WithLocation from '../components/WithLocation'
import Seo from '../components/seo'

const friends = ({ search }) => {
  const { name } = search

  if (!name) return <p>Your not supposed to be here</p>

  return (
    <Layout>
      <Seo title={`${name} you're invited to our wedding`} />
      <Header />

      <Section>
        <Container>
          <p>Hey {name}! </p>
          <p>
            We are super excited to have you at our wedding at Anglers Lodge in
            the Coromandel.
          </p>
        </Container>
      </Section>
      <Section dark>
        <Container>
          <h2>When</h2>
          <p>
            The wedding will be held on Saturday the 28th of March 2020 at about
            2pm. Followed by a light lunch, drinks and dinner at around 6:30pm.
            We expect the majority of guests to be arriving on the Friday night
            before when we will put on a casual dinner and you are more than
            welcome to join.
          </p>
        </Container>
      </Section>
      <Section>
        <Container>
          <h2>Location</h2>
          <p>
            The wedding will be held at Anglers Lodge, Amodeo Bay, 15 minutes
            North of Coromandel Town on the Coromandel Peninsula. It’s a bit out
            of the way, sorry about that, but it’s pretty amazing and a place
            many New Zealanders have not been to.
          </p>
          <h3>Getting there</h3>
          <p>
            Anglers Lodge is about a 3 hour drive from Auckland. The first 2
            hours are pretty easy open road or motorway. The last 1 hour is
            beautiful, but an extremely windy road.
          </p>
        </Container>
      </Section>
      <Section dark>
        <Container>
          <h2>Accommodation</h2>

          <p>
            As the name suggests the wedding venue{' '}
            <a href="https://www.anglers.co.nz/stay/" target="_blank">
              Anglers lodge
            </a>{' '}
            has accommodation, but unfortunately there are no more cabins left.
            We have arranged for free camping for you on the Friday and Saturday
            nights. If you have a tent it would be great if you can bring it. If
            not, let us know and we will try to arrange one for you.
          </p>
          <p>Campervans are also welcome. There are powered sites available.</p>
          <p>
            If camping is not your thing, AirBNB and BookABach are great
            options, but you will want to book early.
          </p>
        </Container>
      </Section>
      <Section>
        <Container>
          <h2>RSVP</h2>
          <p>
            Please let us know if you are coming and if you are camping by the
            16th of Feb by clicking the button below.
          </p>
          <Rsvp guest="friend" />
        </Container>
      </Section>

      <Section dark>
        <Container>
          <h2>Other Stuff</h2>

          <h3>Dress code</h3>
          <p>
            This is going to be a pretty low key affair next to the beach. A
            suit jacket and tie would be overdoing it for the boys, but if
            that’s how you want to roll, good for you!
          </p>
          <h3>Dressing room</h3>
          <p>
            For the ladies who are in tent accommodation, we will have a room
            reserved for getting ready on the day with full length mirrors, hair
            dryers/straighteners etc.
          </p>
          <h3>Dietary requirements</h3>
          <p>
            If you have an allergy that will kill your or are vegetarian/vegan
            let us know. If you are simply “intolerant” you should harden up and
            eat the deliciousness! But if it's not too weird, let us know and
            we'll ask the caterer if they can accommodate.
          </p>
          <h3>Booze</h3>
          <p>
            All booze is provided at the wedding and limited booze provided the
            night before. There is a liquor store 15 minutes away.
          </p>
          <h3>Food</h3>
          <p>
            Dinner will be provided on the Friday night and of course the
            wedding. Breakfast will be provided on the Saturday and Sunday
            morning, and lunch provided on the day of the wedding. There is a
            mini supermarket, bars and cafes 15 minutes away in Coromandel Town
            for anything else you need. You may also want to bring snacks to
            fight of any hanger between meals!
          </p>

          <h3>The vibe of the thing</h3>
          <p>
            We want everyone to relax and have a nice time, as such you can do
            whatever you like whilst you are there. As we have the place to
            ourselves there will be no other guests to annoy and the wedding
            will end when it ends. It’s possible that you may hear other people
            having a good time.
          </p>
          <p>
            If this sounds like your worst nightmare sleeping beauty, you may
            want to consider alternative accommodation. AirBNB and BookABach are
            great options, but you will want to book early. We will happily
            supply earplugs to help your sleep if staying onsite.
          </p>
          <h3>Around the area/Things to do</h3>
          <p>
            There is swimming, bush walks, fishing (we may have a boat and there
            are charters at the lodge), cafes, bars, abseiling, sailing and more
            (You know how to use google right?).
          </p>
          <p>
            There will be people running around and exploring the area, everyone
            is friendly so tag along.
          </p>

          <h3>Presents</h3>
          <p>
            Presents are not necessary. We appreciate the distance everyone has
            to come to attend the wedding, and are grateful to you for making
            the effort.
          </p>
        </Container>
      </Section>
    </Layout>
  )
}

export default WithLocation(friends)
